/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "scss/base/typography"; //NOTE: This code is always include in compiled css.
@import "../node_modules/keen-slider/keen-slider.scss"; //NOTE: This code is always include in compiled css.
@import "scss/base/fonts"; //NOTE: This code is always include in compiled css.

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.card-sticky {
  @apply drop-shadow-md;
}
.header-sticky {
  @apply bg-white drop-shadow-md;
}

// Generic selectors
img {
  @apply select-none;
}

dialog:not([open]) {
  pointer-events: none;
  opacity: 0;
  display: none;
}

.dots {
  .dot {
    @apply transition-all origin-center;
  }

  .dot--active {
    @apply w-[32px] bg-[var(--color,black)];
  }
}

.stars-5-empty {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'%3E%3Cpath fill='%23D9D9D9' d='m8 0 1.8 5.5h5.8L11 9l1.8 5.6L8 11l-4.7 3.4 1.8-5.6L.4 5.5h5.8L8 0Z'/%3E%3C/svg%3E");
}
.stars-5-full {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'%3E%3Cpath fill='%23F06' d='m8 0 1.8 5.5h5.8L11 9l1.8 5.6L8 11l-4.7 3.4 1.8-5.6L.4 5.5h5.8L8 0Z'/%3E%3C/svg%3E");
}

.active--thumb {
  @apply border-[2px] border-black;
}

.decor-stuck {
  top: 100vh;
}

@layer utilities {
  .contain-layout {
    contain: layout;
  }
}

html {
  scrollbar-gutter: stable;
}

body {
  --tw-color-yellow: rgba(255, 255, 0);
  --tw-color-yellow-light: rgba(255, 255, 0, 0.2);
  --tw-color-purple: rgba(255, 0, 102);
  --tw-color-purple-light: rgba(255, 0, 102, 0.2);
  --tw-color-green: rgba(0, 255, 153);
  --tw-color-green-light: rgba(0, 255, 153, 0.2);
  --tw-color-blue: rgba(0, 118, 228, 1);
  --tw-color-blue-dark: rgba(22, 100, 172, 1);
  --tw-color-blue-light: rgba(51, 204, 255, 0.2);
  --tw-color-gray-light: rgba(246, 245, 245, 1);
  --tw-color-gray: rgba(204, 204, 204, 1);
  --tw-color-deepblue: rgba(0, 0, 51, 1);
}

.product-thumbs-slider-preinit {
  .keen-slider__slide {
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(100% / 3) !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fold-scroll {
  @apply -translate-y-40 duration-700;
}

.rich-content {
  @apply my-4;

  h2 {
    @apply text-[26px] xl:text-[32px] leading-normal font-bold;
  }
  h3 {
    @apply font-bold text-[20px] leading-normal;
  }

  .header-margin {
    @apply m-0 mb-5;
  }

  ul {
    @apply list-disc py-3 px-6 font-medium;
  }

  .gray-card {
    @apply flex flex-row gap-3 p-6 border border-gray-light rounded-xs bg-gray-light my-4;

    h1,
    h2,
    h3 {
      @apply mt-0;
    }
  }

  .border-gray-card {
    @apply flex flex-row gap-3 p-6 border-2 border-gray-light rounded-xs my-4;

    h1,
    h2,
    h3 {
      @apply mt-0;
    }
  }

  .card-icon {
    @apply grow-0 shrink-0;
  }

  .no-margin-header {
    @apply m-0 mb-3;
  }

  .product-ingredients {
    @apply flex flex-row gap-2 my-4;

    .packshot {
      @apply grow-0 shrink-0;
    }
  }
}

.custom-captacha-styling {
  img {
    @apply mt-2;
  }

  a {
    @apply inline-block p-2 m-2 text-[14px];
  }
}
